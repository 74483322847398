"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const game_validator_1 = require("@src/app/directives/game-validator");
const gcd = require('compute-gcd');
class GcfcalculatorCtrl {
    constructor($scope, $timeout, $filter, actionService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.actionService = actionService;
    }
    $onInit() {
    }
    transformValue(value) {
        if (value != undefined) {
            return value.split(/[\s\;\\n\,]/g).filter((value) => {
                return (value.length != 0);
            }).map((value) => Number(value));
        }
        else {
            return [];
        }
    }
    submitForm($form) {
        if ($form.$valid && (this.value != undefined)) {
            const values = this.transformValue(this.value);
            const gcf = gcd(values);
            this.result = {
                gcf: gcf,
                gcf_formatted: this.$filter('number')(gcf, 0),
                values: values
            };
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.value = undefined;
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.gcf);
        }
    }
}
GcfcalculatorCtrl.$inject = ['$scope', '$timeout', '$filter', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.directive('commaLength', game_validator_1.CommaLengthValidator);
appModule.component('gameGcfcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: GcfcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('gcfcalculator/');
    }]);
